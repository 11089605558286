

import {
  alertController,
  IonButton,
  IonButtons,
  IonCard,
  IonCardHeader,
  IonCardSubtitle,
  IonCardTitle,
  IonCol,
  IonContent,
  IonDatetime,
  IonHeader,
  IonIcon,
  IonLabel,
  IonMenuButton,
  IonPage,
  IonRow,
  IonSpinner,
  IonTitle,
  IonToolbar,
  toastController,
  IonChip
} from '@ionic/vue';
import axios from 'axios';
import {defineComponent} from 'vue';
import {accessibilityOutline, accessibilitySharp, eyeOutline, eyeSharp, alertCircleOutline, alertCircleSharp, pricetagsOutline, pricetagsSharp } from 'ionicons/icons';
import moment from 'moment';

export default defineComponent({
name: "editInserat",
  data () {
    return {
      star: null,
      isLoading: false,
      inserat: null,

      raffleEntriesCount: 0,
      auctionBidsCount: 0,
      bidIconIos: pricetagsOutline,
      bidIconMd: pricetagsSharp,
      viewsIconIos: eyeOutline,
      viewsIconMd: eyeSharp,
      entriesIconIos: accessibilityOutline,
      entriesIconMd: accessibilitySharp,
      userIsOwner: false,
      inseratCanGoOnline: false,
      inseratNeedsRaffleEndDate: false,
      inseratNeedsAuctionDates: false,
      minimumEndDate: null,
      maximumEndDate: null,
      raffleEndDate: null,
      raffleEndTime: null,
      inseratAddedRaffleEndDate: false,
      auctionStartAtDate: null,
      auctionStartAtTime: null,
      auctionEndAtDate: null,
      auctionEndAtTime: null,
      inseratAddedAuctionDates: false,
      inseratCanBeSubmitted: false,
      alertIconIos: alertCircleOutline,
      alertIconMd: alertCircleSharp
    }
  },
  filter: {
    formatDate(value){
      if (value) {
        return moment(String(value)).format('DD.MM.YYY HH:mm');
      }
    }
  },
  components: {
    IonButtons,
    IonContent,
    IonHeader,
    IonMenuButton,
    IonPage,
    IonTitle,
    IonToolbar,
    IonCard,
    IonCardHeader,
    IonCardSubtitle,
    IonCardTitle,
    IonSpinner,
    IonLabel,
    IonButton,
    IonIcon,
    IonRow,
    IonCol,
    IonDatetime,
    IonChip
  },
  created() {

    if (localStorage.deletedInserat === "true"){
        localStorage.deletedInserat = "false";
        this.$router.push({ path: '/profile/inserats'});
    } else {

      // set min max end dates
      this.minimumEndDate = moment().add(5,'days').format('YYYY-MM-DD');
      this.raffleEndDate = moment().add(5,'days').format('YYYY-MM-DD');
      this.maximumEndDate = moment().add(30,'days').format('YYYY-MM-DD');
      this.raffleEndTime = moment().format('HH:') + '00';
      this.auctionStartAtDate = moment().add(1,'days').format('YYYY-MM-DD');
      this.auctionEndAtDate = moment().add(5,'days').format('YYYY-MM-DD');
      this.auctionStartAtTime = moment().format('HH:') + '00';
      this.auctionEndAtTime = moment().format('HH:') + '00';

      // get inserat data
      this.isLoading = true;
      axios.get(process.env.VUE_APP_API_URL + '/inserats/' + this.$route.params.id, {
        headers: {
          'Authorization': `Bearer ` + localStorage.userToken
        }
      })
          .then(response => {
            this.inserat = response.data;

            // check if inserat can be submitted
            if (this.inserat.isSubmitted === false){
              this.checkIfInseratCanBeSubmitted();
            }

            // get inserat raffle entries
            if (this.inserat.inseratType == "directbuy"){

              const formData = new FormData();
              formData.append("inserat", this.inserat.uuid);

              axios.post(process.env.VUE_APP_API_URL + '/inserat-entries-count', formData, {
                headers: {
                  'Authorization': `Bearer ` + localStorage.userToken
                }
              }).then(result => {
                  this.raffleEntriesCount = result.data.count;
              });

            }

            // get inserat auction bids
            if (this.inserat.inseratType == "auction"){

              const formData = new FormData();
              formData.append("inserat", this.inserat.uuid);

              axios.post(process.env.VUE_APP_API_URL + '/inserat-auction-bids-count', formData, {
                headers: {
                  'Authorization': `Bearer ` + localStorage.userToken
                }
              }).then(result => {
                this.auctionBidsCount = result.data.count;
              });

            }

            // check if inserat can go online
            if (this.inserat.isActive === false && this.inserat.isVerified === true && this.inserat.isSubmitted === true) {

              if (this.inserat.inseratType == "directbuy" || this.inserat.inseratType == "gift"){

                // check if raffleEndDate is set
                    if (this.inserat.raffleEndAt){
                        this.inseratCanGoOnline = true;
                        this.inseratAddedRaffleEndDate = true;
                    } else {
                        this.inseratNeedsRaffleEndDate = true;
                    }
                } else if (this.inserat.inseratType == "auction"){

                  // check if auctionStartAt and auctionEndAt is set
                  if (this.inserat.auctionStartAt && this.inserat.auctionEndAt){
                    this.inseratCanGoOnline = true;
                    this.inseratAddedAuctionDates = true;
                  } else {
                    this.inseratNeedsAuctionDates = true;
                  }
              }

            }

          })
          .catch(error => {
            console.log(error);
          })
          .finally(() => {
            this.isLoading = false;
          });

      // check user ownership
      if (localStorage.userId && localStorage.userToken){

        const formData = new FormData();
        formData.append("inserat", this.$route.params.id);

        axios.post(process.env.VUE_APP_API_URL + '/inserat-owner', formData, {
          headers: {
            'Authorization': `Bearer ` + localStorage.userToken
          }
        })
            .then(result => {
              if (result.data.status){
                this.userIsOwner = true;
              }
            });
      } else {
        this.$router.push({ path: '/login'});
      }
    }



  },
  methods: {
    setInseratOnline() {
      const formData = new FormData();
      formData.append("inserat", this.inserat.uuid);

      axios.post(process.env.VUE_APP_API_URL + '/inserat-set-online', formData, {
        headers: {
          'Authorization': `Bearer ` + localStorage.userToken
        }
      })
          .then(() => {
            this.inserat.isActive = true;
            this.inseratCanGoOnline = false;
            this.openToast('Inserat ist jetzt online.', 2500, 'warning');
          })
          .catch(error => {
            console.log(error);
          });
    },
    setInseratOffline(){
      const formData = new FormData();
      formData.append("inserat", this.inserat.uuid);

      axios.post(process.env.VUE_APP_API_URL + '/inserat-set-offline', formData, {
        headers: {
          'Authorization': `Bearer ` + localStorage.userToken
        }
      })
          .then(() => {
            this.inserat.isActive = false;
            this.inseratCanGoOnline = true;
            this.inseratAddedRaffleEndDate = true;
            this.openToast('Inserat ist jetzt offline.', 2500, 'warning');
          })
          .catch(error => {
            console.log(error);
          });
    },
    addImage() {
        this.$router.push({ path: '/inserat/' + this.inserat.uuid + '/edit/add-image'});
    },
    checkIfInseratCanBeSubmitted(){
      if (this.inserat.inseratImages.length > 0){
        this.inseratCanBeSubmitted = true;
      }
    },
    submit() {

      const formData = new FormData();
      formData.append("inserat", this.$route.params.id);

      // submit inserat for checking, url: /submit-inserat
      axios.post(process.env.VUE_APP_API_URL + '/submit-inserat', formData, {
        headers: {
          'Authorization': `Bearer ` + localStorage.userToken
        }
      })
          .then(() => {
            this.inserat.isSubmitted = true;
          })
          .catch(error => {
            console.log(error);
          });
    },
    formatDate(date){
        return moment(date).format('DD.MM.YYYY HH:mm');
    },
    raffleEndDateChanged(){
      this.raffleEndDate = moment(this.raffleEndDate).format("YYYY-MM-DD");
    },
    auctionStartAtDateChanged(){
      this.auctionStartAtDate = moment(this.auctionStartAtDate).format("YYYY-MM-DD");
    },
    auctionEndAtDateChanged(){
      this.auctionEndAtDate = moment(this.auctionEndAtDate).format("YYYY-MM-DD");
    },
    addRaffleEndDateTime(){

      const raffleDateTime = moment(this.raffleEndDate + " " + this.raffleEndTime).format('YYYY-MM-DD HH:mm');
      const formData = new FormData();
      formData.append("raffleEndAt", raffleDateTime);
      formData.append("inserat", this.$route.params.id);

      this.isLoading = true;
      axios.post(process.env.VUE_APP_API_URL + '/inserat-add-raffle-end', formData, {
        headers: {
          'Authorization': `Bearer ` + localStorage.userToken
        }
      })
          .then(() => {
            this.openToast('Zeitraum hinzugefügt.', 1000, 'success');
            this.inseratNeedsRaffleEndDate = false;
            this.inseratAddedRaffleEndDate = true;
            this.inseratCanGoOnline = true;
            this.inserat.raffleEndAt = raffleDateTime;
          }).finally(() => {
            this.isLoading = false;
          });

    },
    addAuctionDates(){

      const auctionStartAt = moment(this.auctionStartAtDate + " " + this.auctionStartAtTime).format('YYYY-MM-DD HH:mm');
      const auctionEndAt = moment(this.auctionEndAtDate + " " + this.auctionEndAtTime).format('YYYY-MM-DD HH:mm');

      const formData = new FormData();
      formData.append("startAt", auctionStartAt);
      formData.append("endAt", auctionEndAt);
      formData.append("inserat", this.$route.params.id);

      this.isLoading = true;
      axios.post(process.env.VUE_APP_API_URL + '/inserat-add-auction-dates', formData, {
        headers: {
          'Authorization': `Bearer ` + localStorage.userToken
        }
      })
          .then(() => {
            this.openToast('Auktions-Zeitraum hinzugefügt.', 1000, 'success');
            this.inseratNeedsAuctionDates = false;
            this.inseratAddedAuctionDates = true;
            this.inseratCanGoOnline = true;
            this.inserat.auctionStartAt = auctionStartAt;
            this.inserat.auctionEndAt = auctionEndAt;
          }).finally(() => {
        this.isLoading = false;
      });

    },
    confirmDelete() {
        this.presentAlertConfirm();
    },
    delete(){
        this.isLoading = true;
        const formData = new FormData();
        formData.append("inserat", this.$route.params.id);
        axios.post(process.env.VUE_APP_API_URL + '/delete-inserat', formData, {
          headers: {
            'Authorization': `Bearer ` + localStorage.userToken
          }
        })
        .then(() => {
            this.isLoading = false;
            this.openToast('Inserat gelöscht.', 1000, 'danger');
            setTimeout(this.redirectToUserInserats, 1000);
        });
    },
    async redirectToUserInserats(){
      localStorage.deletedInserat = true;
      await this.$router.go(0);
    },
    async presentAlertConfirm() {
      const alert = await alertController
          .create({
            cssClass: 'my-custom-class',
            header: 'Bestätigen',
            message: 'Willst du dieses Inserat wirklich löschen?',
            buttons: [
              {
                text: 'Abbrechen',
                role: 'cancel',
                cssClass: 'secondary',
                handler: blah => {
                  console.log('Confirm Cancel:', blah)
                },
              },
              {
                text: 'Löschen',
                handler: () => {
                    this.delete();
                },
              },
            ],
          });
      return alert.present();
    },
    getRouteToInserat() {
        return "/inserat/" + this.$route.params.id;
    },
    editInseratData() {
        if (this.inserat.isActive == true){
          this.openToast('Dein Inserat muss offline sein, damit du es bearbeiten kannst.', 5000);
        } else {
          this.$router.push({ path: '/inserat/' + this.inserat.uuid + "/edit/edit-data"})
        }
    },
    activateAutomaticRenewal() {
      if (this.inserat.isActive == true){
        this.openToast('Dein Inserat muss offline sein, damit du es bearbeiten kannst.', 5000);
      } else {
        this.$router.push({ path: '/inserat/' + this.inserat.uuid + "/edit/activate-automatic-renewal"})
      }
    },
    async openToast(message: string, duration: number, color: string) {
      if (!color){ color = "primary";}
      const toast = await toastController
          .create({
            message: message,
            duration: duration,
          })
      return toast.present();
    },
  }
});
