<template>
  <ion-page>
    <ion-header :translucent="true">
      <ion-toolbar>
        <ion-buttons slot="start">
          <ion-menu-button color="primary"></ion-menu-button>
        </ion-buttons>
        <ion-title>Inserat bearbeiten</ion-title>
      </ion-toolbar>
    </ion-header>

    <div class="container" v-if="isLoading">
      <ion-spinner name="dots"></ion-spinner>
    </div>

    <ion-content v-if="!isLoading && userIsOwner">

      <ion-card>
        <img v-if="inserat.inseratImages[0]" :src="inserat.inseratImages[0].accessUrl" alt="thumbnail">
        <ion-card-header>
          <ion-chip v-if="inserat.isActive === true" style="width: 100%; text-align: center; justify-content: center; margin-bottom: 20px;" color="success">
            <ion-label>Online</ion-label>
          </ion-chip>
          <ion-card-title>{{ inserat.title }}</ion-card-title>
          <ion-label class="ion-text-wrap">{{ inserat.description }}</ion-label>
          <ion-button style="width: 100%; margin-top: 20px;" @click="editInseratData">Inserat Daten bearbeiten</ion-button>
          <ion-button v-if="inserat.isActive === true" style="width: 100%; margin-top: 20px;" color="secondary" :router-link="getRouteToInserat()">Inserat anschauen</ion-button>
        </ion-card-header>
      </ion-card>

      <ion-card v-if="!inserat.inseratImages[0]">
        <ion-card-header>
          <ion-card-subtitle><ion-icon :ios="alertIconIos" :md="alertIconMd"></ion-icon> Bild hinzufügen</ion-card-subtitle>
          <small>Damit du dein Inserat zur Prüfung abschicken kannst, musst du ein Bild hinzufügen.</small>
          <ion-button color="secondary" style="width: 100%; margin-top: 20px;" @click="addImage">Jetzt hinzufügen</ion-button>
        </ion-card-header>
      </ion-card>

      <ion-row v-if="inserat.isActive === true">
        <ion-col :size="6">
          <ion-card style="margin-right: 5px; margin-top: 0px; margin-bottom: 0px;">
            <ion-card-header>
              <ion-card-subtitle>Views</ion-card-subtitle>
              <ion-label><ion-icon :ios="viewsIconIos" :md="viewsIconMd"></ion-icon> {{ inserat.views }} Views</ion-label>
            </ion-card-header>
          </ion-card>
        </ion-col>
        <ion-col size="6" v-if="inserat.inseratType == 'directbuy' || inserat.inseratType == 'gift' ">
          <ion-card style="margin-left: 5px; margin-top: 0px; margin-bottom: 0px;">
            <ion-card-header>
              <ion-card-subtitle>Teilnahmen</ion-card-subtitle>
              <ion-label><ion-icon :ios="entriesIconIos" :md="entriesIconMd"></ion-icon> {{ raffleEntriesCount }} Teilnahmen</ion-label>
            </ion-card-header>
          </ion-card>
        </ion-col>
        <ion-col size="6" v-if="inserat.inseratType == 'auction'">
          <ion-card style="margin-left: 5px; margin-top: 0px; margin-bottom: 0px;">
            <ion-card-header>
              <ion-card-subtitle>Gebote</ion-card-subtitle>
              <ion-label><ion-icon :ios="bidIconIos" :md="bidIconMd"></ion-icon> {{ auctionBidsCount }} Gebote</ion-label>
            </ion-card-header>
          </ion-card>
        </ion-col>
      </ion-row>

      <ion-card>
        <ion-card-header>
          <ion-card-subtitle>Automatische Verlängerung</ion-card-subtitle>
          <ion-card-title v-if="inserat.automaticIncreaseTime">um {{ inserat.addDays }} Tage</ion-card-title>
          <small>Aktiviere diese Option und lege fest um wie viele Tage dein Inserat verlängert werden soll, wenn zum Endzeitpunkt kein Käufer gefunden wird.</small>
          <ion-button v-if="!inserat.automaticIncreaseTime" style="width: 100%; margin-top: 10px;" @click="activateAutomaticRenewal">Aktivieren</ion-button>
          <ion-button v-if="inserat.automaticIncreaseTime" style="width: 100%; margin-top: 10px;" @click="activateAutomaticRenewal" color="warning">Deaktivieren</ion-button>
        </ion-card-header>
      </ion-card>

      <ion-card v-if="inserat.isVerified && inseratAddedRaffleEndDate && !inserat.isActive">
        <ion-card-header>
          <ion-card-subtitle>Inserat Teilnahmeschluss</ion-card-subtitle>
          <small>(Datum und Uhrzeit anklicken zum verändern)</small>
          <ion-card-title @click="inseratNeedsRaffleEndDate = true; inseratAddedRaffleEndDate = false">{{ formatDate(inserat.raffleEndAt) }} Uhr</ion-card-title>
        </ion-card-header>
      </ion-card>

      <ion-card v-if="inserat.isVerified && inseratNeedsRaffleEndDate && !inserat.isActive">
        <ion-card-header>
          <ion-card-subtitle>Inserat Teilnahmeschluss</ion-card-subtitle>
          <small>(Datum und Uhrzeit anklicken zum verändern)</small>
          <ion-row>
            <ion-col size="6">
              <ion-datetime display-format="DD.MM.YYYY" :min="minimumEndDate" :max="maximumEndDate" v-model="raffleEndDate" v-on:ionChange="raffleEndDateChanged"></ion-datetime>
            </ion-col>
            <ion-col size="6">
              <ion-datetime display-format="HH:mm" minute-values="0,15,30,45" v-model="raffleEndTime"></ion-datetime>
            </ion-col>
          </ion-row>
          <ion-button color="secondary" style="width: 100%;" @click="addRaffleEndDateTime">Jetzt hinzufügen</ion-button>
        </ion-card-header>
      </ion-card>

      <ion-card v-if="inserat.isVerified && inseratAddedAuctionDates && !inserat.isActive">
        <ion-card-header>
          <ion-card-subtitle>Auktions Zeitraum</ion-card-subtitle>
          <small>(Datum und Uhrzeit anklicken zum verändern)</small><br>
          <small>Auktionsstart:</small><br>
          <ion-card-title @click="inseratNeedsAuctionDates = true; inseratAddedAuctionDates = false">{{ formatDate(inserat.auctionStartAt) }} Uhr</ion-card-title>
          <small>Auktionsende:</small><br>
          <ion-card-title @click="inseratNeedsAuctionDates = true; inseratAddedAuctionDates = false">{{ formatDate(inserat.auctionEndAt) }} Uhr</ion-card-title>
        </ion-card-header>
      </ion-card>

      <ion-card v-if="inserat.isVerified && inseratNeedsAuctionDates && !inserat.isActive">
        <ion-card-header>
          <ion-card-subtitle>Auktions Zeitraum hinzufügen</ion-card-subtitle>
          <small>(Datum und Uhrzeit anklicken zum verändern)</small><br>
          <small>Auktionsstart</small>
          <ion-row>
            <ion-col size="6">
              <ion-datetime display-format="DD.MM.YYYY" :min="minimumEndDate" :max="maximumEndDate" v-model="auctionStartAtDate" v-on:ionChange="auctionStartAtDateChanged"></ion-datetime>
            </ion-col>
            <ion-col size="6">
              <ion-datetime display-format="HH:mm" minute-values="0,15,30,45" v-model="auctionStartAtTime"></ion-datetime>
            </ion-col>
          </ion-row>
          <small>Auktionsende:</small>
          <ion-row>
            <ion-col size="6">
              <ion-datetime display-format="DD.MM.YYYY" :min="minimumEndDate" :max="maximumEndDate" v-model="auctionEndAtDate" v-on:ionChange="auctionEndAtDateChanged"></ion-datetime>
            </ion-col>
            <ion-col size="6">
              <ion-datetime display-format="HH:mm" minute-values="0,15,30,45" v-model="auctionEndAtTime"></ion-datetime>
            </ion-col>
          </ion-row>
          <ion-button color="secondary" style="width: 100%;" @click="addAuctionDates">Jetzt hinzufügen</ion-button>
        </ion-card-header>
      </ion-card>

      <ion-card v-if="inserat.isSubmitted === false && inseratCanBeSubmitted">
        <ion-card-header>
          <ion-card-subtitle>Inserat zur Prüfung abschicken</ion-card-subtitle>
          <ion-button color="secondary" style="width: 100%;" @click="submit">Abschicken</ion-button>
        </ion-card-header>
      </ion-card>

      <ion-card v-if="inserat.isSubmitted === true && inserat.isVerified === false">
        <ion-card-header>
          <ion-card-subtitle>Inserat Prüfungsstatus</ion-card-subtitle>
          <ion-card-title>Warten auf Prüfung</ion-card-title>
        </ion-card-header>
      </ion-card>

      <ion-card v-if="inserat.isSubmitted === true && inserat.isVerified === true && inserat.isActive === false">
        <ion-card-header>
          <ion-card-subtitle>Inserat Prüfungsstatus</ion-card-subtitle>
          <ion-card-title color="secondary">Bestätigt</ion-card-title>
          <small v-if="inseratNeedsRaffleEndDate">Füge einen Teilnahmeschluss hinzu, damit du dein Inserat Online stellen kannst.</small>
          <small v-if="inseratNeedsAuctionDates">Füge ein Auktionsende hinzu, damit du dein Inserat Online stellen kannst.</small>
        </ion-card-header>
      </ion-card>

      <ion-card v-if="inseratCanGoOnline && inserat.isVerified === true && inserat.isActive === false">
        <ion-card-header>
          <ion-card-subtitle>Inserat Online Stellen</ion-card-subtitle>
          <ion-button color="success" style="width: 100%;" @click="setInseratOnline">Online stellen</ion-button>
        </ion-card-header>
      </ion-card>

      <ion-card v-if="inserat.isActive === true">
        <ion-card-header>
          <ion-card-subtitle>Inserat Offline Stellen</ion-card-subtitle>
          <ion-button color="warning" style="width: 100%;" @click="setInseratOffline">Offline Stellen</ion-button>
        </ion-card-header>
      </ion-card>

      <ion-card>
        <ion-card-header>
          <ion-card-subtitle>Inserat Löschen</ion-card-subtitle>
          <ion-button color="danger" style="width: 100%;" @click="confirmDelete">Inserat Löschen</ion-button>
        </ion-card-header>
      </ion-card>

    </ion-content>

  </ion-page>
</template>

<script lang="ts">

import {
  alertController,
  IonButton,
  IonButtons,
  IonCard,
  IonCardHeader,
  IonCardSubtitle,
  IonCardTitle,
  IonCol,
  IonContent,
  IonDatetime,
  IonHeader,
  IonIcon,
  IonLabel,
  IonMenuButton,
  IonPage,
  IonRow,
  IonSpinner,
  IonTitle,
  IonToolbar,
  toastController,
  IonChip
} from '@ionic/vue';
import axios from 'axios';
import {defineComponent} from 'vue';
import {accessibilityOutline, accessibilitySharp, eyeOutline, eyeSharp, alertCircleOutline, alertCircleSharp, pricetagsOutline, pricetagsSharp } from 'ionicons/icons';
import moment from 'moment';

export default defineComponent({
name: "editInserat",
  data () {
    return {
      star: null,
      isLoading: false,
      inserat: null,

      raffleEntriesCount: 0,
      auctionBidsCount: 0,
      bidIconIos: pricetagsOutline,
      bidIconMd: pricetagsSharp,
      viewsIconIos: eyeOutline,
      viewsIconMd: eyeSharp,
      entriesIconIos: accessibilityOutline,
      entriesIconMd: accessibilitySharp,
      userIsOwner: false,
      inseratCanGoOnline: false,
      inseratNeedsRaffleEndDate: false,
      inseratNeedsAuctionDates: false,
      minimumEndDate: null,
      maximumEndDate: null,
      raffleEndDate: null,
      raffleEndTime: null,
      inseratAddedRaffleEndDate: false,
      auctionStartAtDate: null,
      auctionStartAtTime: null,
      auctionEndAtDate: null,
      auctionEndAtTime: null,
      inseratAddedAuctionDates: false,
      inseratCanBeSubmitted: false,
      alertIconIos: alertCircleOutline,
      alertIconMd: alertCircleSharp
    }
  },
  filter: {
    formatDate(value){
      if (value) {
        return moment(String(value)).format('DD.MM.YYY HH:mm');
      }
    }
  },
  components: {
    IonButtons,
    IonContent,
    IonHeader,
    IonMenuButton,
    IonPage,
    IonTitle,
    IonToolbar,
    IonCard,
    IonCardHeader,
    IonCardSubtitle,
    IonCardTitle,
    IonSpinner,
    IonLabel,
    IonButton,
    IonIcon,
    IonRow,
    IonCol,
    IonDatetime,
    IonChip
  },
  created() {

    if (localStorage.deletedInserat === "true"){
        localStorage.deletedInserat = "false";
        this.$router.push({ path: '/profile/inserats'});
    } else {

      // set min max end dates
      this.minimumEndDate = moment().add(5,'days').format('YYYY-MM-DD');
      this.raffleEndDate = moment().add(5,'days').format('YYYY-MM-DD');
      this.maximumEndDate = moment().add(30,'days').format('YYYY-MM-DD');
      this.raffleEndTime = moment().format('HH:') + '00';
      this.auctionStartAtDate = moment().add(1,'days').format('YYYY-MM-DD');
      this.auctionEndAtDate = moment().add(5,'days').format('YYYY-MM-DD');
      this.auctionStartAtTime = moment().format('HH:') + '00';
      this.auctionEndAtTime = moment().format('HH:') + '00';

      // get inserat data
      this.isLoading = true;
      axios.get(process.env.VUE_APP_API_URL + '/inserats/' + this.$route.params.id, {
        headers: {
          'Authorization': `Bearer ` + localStorage.userToken
        }
      })
          .then(response => {
            this.inserat = response.data;

            // check if inserat can be submitted
            if (this.inserat.isSubmitted === false){
              this.checkIfInseratCanBeSubmitted();
            }

            // get inserat raffle entries
            if (this.inserat.inseratType == "directbuy"){

              const formData = new FormData();
              formData.append("inserat", this.inserat.uuid);

              axios.post(process.env.VUE_APP_API_URL + '/inserat-entries-count', formData, {
                headers: {
                  'Authorization': `Bearer ` + localStorage.userToken
                }
              }).then(result => {
                  this.raffleEntriesCount = result.data.count;
              });

            }

            // get inserat auction bids
            if (this.inserat.inseratType == "auction"){

              const formData = new FormData();
              formData.append("inserat", this.inserat.uuid);

              axios.post(process.env.VUE_APP_API_URL + '/inserat-auction-bids-count', formData, {
                headers: {
                  'Authorization': `Bearer ` + localStorage.userToken
                }
              }).then(result => {
                this.auctionBidsCount = result.data.count;
              });

            }

            // check if inserat can go online
            if (this.inserat.isActive === false && this.inserat.isVerified === true && this.inserat.isSubmitted === true) {

              if (this.inserat.inseratType == "directbuy" || this.inserat.inseratType == "gift"){

                // check if raffleEndDate is set
                    if (this.inserat.raffleEndAt){
                        this.inseratCanGoOnline = true;
                        this.inseratAddedRaffleEndDate = true;
                    } else {
                        this.inseratNeedsRaffleEndDate = true;
                    }
                } else if (this.inserat.inseratType == "auction"){

                  // check if auctionStartAt and auctionEndAt is set
                  if (this.inserat.auctionStartAt && this.inserat.auctionEndAt){
                    this.inseratCanGoOnline = true;
                    this.inseratAddedAuctionDates = true;
                  } else {
                    this.inseratNeedsAuctionDates = true;
                  }
              }

            }

          })
          .catch(error => {
            console.log(error);
          })
          .finally(() => {
            this.isLoading = false;
          });

      // check user ownership
      if (localStorage.userId && localStorage.userToken){

        const formData = new FormData();
        formData.append("inserat", this.$route.params.id);

        axios.post(process.env.VUE_APP_API_URL + '/inserat-owner', formData, {
          headers: {
            'Authorization': `Bearer ` + localStorage.userToken
          }
        })
            .then(result => {
              if (result.data.status){
                this.userIsOwner = true;
              }
            });
      } else {
        this.$router.push({ path: '/login'});
      }
    }



  },
  methods: {
    setInseratOnline() {
      const formData = new FormData();
      formData.append("inserat", this.inserat.uuid);

      axios.post(process.env.VUE_APP_API_URL + '/inserat-set-online', formData, {
        headers: {
          'Authorization': `Bearer ` + localStorage.userToken
        }
      })
          .then(() => {
            this.inserat.isActive = true;
            this.inseratCanGoOnline = false;
            this.openToast('Inserat ist jetzt online.', 2500, 'warning');
          })
          .catch(error => {
            console.log(error);
          });
    },
    setInseratOffline(){
      const formData = new FormData();
      formData.append("inserat", this.inserat.uuid);

      axios.post(process.env.VUE_APP_API_URL + '/inserat-set-offline', formData, {
        headers: {
          'Authorization': `Bearer ` + localStorage.userToken
        }
      })
          .then(() => {
            this.inserat.isActive = false;
            this.inseratCanGoOnline = true;
            this.inseratAddedRaffleEndDate = true;
            this.openToast('Inserat ist jetzt offline.', 2500, 'warning');
          })
          .catch(error => {
            console.log(error);
          });
    },
    addImage() {
        this.$router.push({ path: '/inserat/' + this.inserat.uuid + '/edit/add-image'});
    },
    checkIfInseratCanBeSubmitted(){
      if (this.inserat.inseratImages.length > 0){
        this.inseratCanBeSubmitted = true;
      }
    },
    submit() {

      const formData = new FormData();
      formData.append("inserat", this.$route.params.id);

      // submit inserat for checking, url: /submit-inserat
      axios.post(process.env.VUE_APP_API_URL + '/submit-inserat', formData, {
        headers: {
          'Authorization': `Bearer ` + localStorage.userToken
        }
      })
          .then(() => {
            this.inserat.isSubmitted = true;
          })
          .catch(error => {
            console.log(error);
          });
    },
    formatDate(date){
        return moment(date).format('DD.MM.YYYY HH:mm');
    },
    raffleEndDateChanged(){
      this.raffleEndDate = moment(this.raffleEndDate).format("YYYY-MM-DD");
    },
    auctionStartAtDateChanged(){
      this.auctionStartAtDate = moment(this.auctionStartAtDate).format("YYYY-MM-DD");
    },
    auctionEndAtDateChanged(){
      this.auctionEndAtDate = moment(this.auctionEndAtDate).format("YYYY-MM-DD");
    },
    addRaffleEndDateTime(){

      const raffleDateTime = moment(this.raffleEndDate + " " + this.raffleEndTime).format('YYYY-MM-DD HH:mm');
      const formData = new FormData();
      formData.append("raffleEndAt", raffleDateTime);
      formData.append("inserat", this.$route.params.id);

      this.isLoading = true;
      axios.post(process.env.VUE_APP_API_URL + '/inserat-add-raffle-end', formData, {
        headers: {
          'Authorization': `Bearer ` + localStorage.userToken
        }
      })
          .then(() => {
            this.openToast('Zeitraum hinzugefügt.', 1000, 'success');
            this.inseratNeedsRaffleEndDate = false;
            this.inseratAddedRaffleEndDate = true;
            this.inseratCanGoOnline = true;
            this.inserat.raffleEndAt = raffleDateTime;
          }).finally(() => {
            this.isLoading = false;
          });

    },
    addAuctionDates(){

      const auctionStartAt = moment(this.auctionStartAtDate + " " + this.auctionStartAtTime).format('YYYY-MM-DD HH:mm');
      const auctionEndAt = moment(this.auctionEndAtDate + " " + this.auctionEndAtTime).format('YYYY-MM-DD HH:mm');

      const formData = new FormData();
      formData.append("startAt", auctionStartAt);
      formData.append("endAt", auctionEndAt);
      formData.append("inserat", this.$route.params.id);

      this.isLoading = true;
      axios.post(process.env.VUE_APP_API_URL + '/inserat-add-auction-dates', formData, {
        headers: {
          'Authorization': `Bearer ` + localStorage.userToken
        }
      })
          .then(() => {
            this.openToast('Auktions-Zeitraum hinzugefügt.', 1000, 'success');
            this.inseratNeedsAuctionDates = false;
            this.inseratAddedAuctionDates = true;
            this.inseratCanGoOnline = true;
            this.inserat.auctionStartAt = auctionStartAt;
            this.inserat.auctionEndAt = auctionEndAt;
          }).finally(() => {
        this.isLoading = false;
      });

    },
    confirmDelete() {
        this.presentAlertConfirm();
    },
    delete(){
        this.isLoading = true;
        const formData = new FormData();
        formData.append("inserat", this.$route.params.id);
        axios.post(process.env.VUE_APP_API_URL + '/delete-inserat', formData, {
          headers: {
            'Authorization': `Bearer ` + localStorage.userToken
          }
        })
        .then(() => {
            this.isLoading = false;
            this.openToast('Inserat gelöscht.', 1000, 'danger');
            setTimeout(this.redirectToUserInserats, 1000);
        });
    },
    async redirectToUserInserats(){
      localStorage.deletedInserat = true;
      await this.$router.go(0);
    },
    async presentAlertConfirm() {
      const alert = await alertController
          .create({
            cssClass: 'my-custom-class',
            header: 'Bestätigen',
            message: 'Willst du dieses Inserat wirklich löschen?',
            buttons: [
              {
                text: 'Abbrechen',
                role: 'cancel',
                cssClass: 'secondary',
                handler: blah => {
                  console.log('Confirm Cancel:', blah)
                },
              },
              {
                text: 'Löschen',
                handler: () => {
                    this.delete();
                },
              },
            ],
          });
      return alert.present();
    },
    getRouteToInserat() {
        return "/inserat/" + this.$route.params.id;
    },
    editInseratData() {
        if (this.inserat.isActive == true){
          this.openToast('Dein Inserat muss offline sein, damit du es bearbeiten kannst.', 5000);
        } else {
          this.$router.push({ path: '/inserat/' + this.inserat.uuid + "/edit/edit-data"})
        }
    },
    activateAutomaticRenewal() {
      if (this.inserat.isActive == true){
        this.openToast('Dein Inserat muss offline sein, damit du es bearbeiten kannst.', 5000);
      } else {
        this.$router.push({ path: '/inserat/' + this.inserat.uuid + "/edit/activate-automatic-renewal"})
      }
    },
    async openToast(message: string, duration: number, color: string) {
      if (!color){ color = "primary";}
      const toast = await toastController
          .create({
            message: message,
            duration: duration,
          })
      return toast.present();
    },
  }
});
</script>

<style scoped>

.container {
  text-align: center;
  position: absolute;
  left: 0;
  right: 0;
  top: 50%;
  transform: translateY(-50%);
}

.container strong {
  font-size: 20px;
  line-height: 26px;
}

.container p {
  font-size: 16px;
  line-height: 22px;
  color: #8c8c8c;
  margin: 0;
}

.container a {
  text-decoration: none;
}

</style>
